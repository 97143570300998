import React, { ReactElement, useMemo } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

interface SlackOAuthProps {
  loader: ReactElement;
}

export default function SlackOAuth(props: SlackOAuthProps) {
  const [searchParams] = useSearchParams();
  const stateParam = searchParams.get('state');
  const state = useMemo(() => {
    if (!stateParam) return null;
    try {
      return JSON.parse(stateParam);
    } catch (err) {
      console.error(err);
      return null;
    }
  }, [stateParam]);
  if (state?.redirectUrl) {
    window.location.href = state.redirectUrl;
    return props.loader;
  }
  return <Navigate to="/" replace />;
}
